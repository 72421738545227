.bingo-card {
  width: 22cm;
  height: 24.5cm;
  margin: auto;
  padding: 10px;
  box-sizing: border-box;
  background: url('./tarjeta_bingo.png') no-repeat center center;
  background-size: cover;
  position: relative;
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 10px;
  position: absolute;
  top: 120px;
  left: 50px;
  right: 50px;
  bottom: 50px;
}

.grid-item img {
  width: 100%;
  height: auto;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
}

